//
// Typography
// --------------------------------------------------

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1;
}
h1, .h1 { font-size: 36px; }
h2, .h2 { font-size: 30px; }
h3, .h3 { font-size: 24px; }
h4, .h4 { font-size: 18px; }
h5, .h5 { font-size: 14px; margin-top: 20px; }
h6, .h6 { font-size: 12px; margin-top: 20px; }

// Paragraphs
p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #777;
}
