body {
  user-select: none;
}

.content {
  margin-bottom: 50px;
}

.panel {
  margin: 10px;

  nav {
    margin-bottom: 10px;

    &.right-left {
      display: flex;
      justify-content: space-between;
    }
  }
}

/*
|--------------------------------------------------------------------------
| Total Panel
|--------------------------------------------------------------------------
*/

.card {
  border-radius: 3px;
  padding: 7.5px;
}

.budget-hud {
  .total-panel {
    display: flex;
    margin-top: 11px;
    font-size: 15px;
    @include transition(color 0.7s ease-in 0s, border-color 0.7s ease-in 0s);

    .total-cost {
      flex: 1;
    }
    h4 {
      display: inline-block;
      margin: 0;
    }
  }

  &.secure {
    color: darken($brand-success, 25%);
  }

  &.warning {
    color: darken($brand-warning, 20%);
  }

  &.danger {
    color: darken($brand-danger, 20%);
  }

  &.insecure {
    color: darken($brand-danger, 20%);
    background-color: #ffb1b1;
  }
}

.table-view {
  margin-bottom: 60px;

  li.muted {
    color: #8a8a8a;
  }

  .badge {
    font-size: 15px;
  }
}

/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/

label.checkbox {
  display: block;
  padding: 7.5px;

  & ~ button {
    margin-top: 7.5px;
  }
}

.input.group {
  position: relative;

  .input-addon {
    position: absolute;
    top: 7.5px;
    left: 11px;
  }

  input + .input-addon {
    top: 7.5px;
    right: 11px;
    left: inherit;
  }

  input {
    padding-right: 25px;
    padding-left: 25px;
  }
}

/*
|--------------------------------------------------------------------------
| Success Message
|--------------------------------------------------------------------------
*/
.success-check {
  pointer-events: none;
  position: absolute;
  top: 2rem;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  text-align: center;
  height: auto;
  opacity: 0;
  font-size: 20rem;
  color: darken($brand-success, 10%);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 10;

  &.zoom {
    opacity: 0.65;
    transform: scale(1.2);
  }
}

.half-n-half {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 10px;
  }
}
