//
// Cards
// --------------------------------------------------

.card {
  margin: $bar-side-spacing;
  overflow: hidden;
  background-color: $card-bg;
  border: $border-default;
  border-radius: $border-radius;
}


// Cards with table-views
// --------------------------------------------------
.card .table-view {
  margin-bottom: 0;
  border-top: 0;
  border-bottom: 0;

  // Rounding first divider on carded lists and remove border on the top
  .table-view-divider:first-child {
    top: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  // Rounding last divider on carded table views
  .table-view-divider:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
// Remove the bottom border from last table cell
.card .table-view-cell:last-child {
  border-bottom: 0;
}
