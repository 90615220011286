//
// Popovers (to be used with popovers.js)
// --------------------------------------------------

.popover {
  position: fixed;
  top: 55px;
  left: 50%;
  z-index: 20;
  display: none;
  width: 280px;
  margin-left: -140px;
  background-color: $chrome-color;
  border-radius: $border-radius;
  opacity: 0;
  @include box-shadow(0 0 15px rgba(0, 0, 0, .1));
  @include transform(translate3d(0, -15px, 0));
  @include transition(all .25s linear);

  // Caret on top of popover using CSS triangles (thanks to @chriscoyier for solution)
  &:before {
    position: absolute;
    top: -15px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    content: '';
    border-right: 15px solid transparent;
    border-bottom: 15px solid $chrome-color;
    border-left: 15px solid transparent;
  }

  // Popover transition
  // --------------------------------------------------

  &.visible {
    opacity: 1;
    @include transform(translate3d(0, 0, 0));
  }

  // Give correct spacing to the content if there is a bar inside the popover.
  .bar ~ .table-view {
    padding-top: $bar-base-height;
  }
}

// Backdrop (used as invisible touch escape)
// --------------------------------------------------

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(0,0,0,.3);
}

// Block level buttons in popovers
// --------------------------------------------------

.popover .btn-block {
  margin-bottom: 5px;

  // Remove extra margin on bottom of last button
  &:last-child {
    margin-bottom: 0;
  }
}


// Popovers with nav bars
// --------------------------------------------------

.popover .bar-nav {
  border-bottom: $border-default;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @include box-shadow(none);
}


// Table views in popovers
// --------------------------------------------------

.popover .table-view {
  max-height: 300px;
  margin-bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-top: 0;
  border-bottom: 0;
  border-radius: $border-radius;
}
