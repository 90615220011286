//
// Forms
// --------------------------------------------------

// Force form elements to inherit font styles
input,
textarea,
button,
select {
  font-family: $font-family-default;
  font-size: $font-size-default;
}

// Stretch inputs/textareas to full width and add height to maintain a consistent baseline
select,
textarea,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="color"] {
  width: 100%;
  height: 35px;
  -webkit-appearance: none;
  padding: 0 15px;
  margin-bottom: 15px;
  line-height: $line-height-default;
  background-color: #fff;
  border: $border-default;
  border-radius: 3px;
  outline: none;
}

// Rounded search input
input[type="search"] {
  padding: 0 10px;
  font-size: 16px;
  border-radius: 20px;
  // Override content-box in normalize
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
input[type="search"]:focus {
  text-align: left;
}

// Allow text area's height to grow larger than a normal input
textarea {
  height: auto;
}

// Style select button to look like part of the Ratchet's style
select {
  height: auto;
  font-size: 14px;
  background-color: #f8f8f8;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .1));
}


// Input groups (cluster multiple inputs together into a single group)
// -------------------------------------------------------------------

// Remove spacing, borders, shadows and rounding since it all belongs on the .input-group not the input
.input-group {
  background-color: #fff;
}
.input-group input,
.input-group textarea {
  margin-bottom: 0;
  background-color: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  @include box-shadow(none);
}

// Input groups with labels
// --------------------------------------------------

// To use labels with input groups, wrap a label and an input in an .input-row
.input-row {
  overflow: hidden;
  height: 35px; // Matches the height of inputs.
  border-bottom: $border-default;
}

// Labels get floated left with a set percentage width
.input-row label {
  float: left;
  width: 35%;
  padding: 8px 15px;
  font-family: $font-family-default;
  line-height: 1.1; // Put the text on the baseline.
}

// Actual inputs float to right of labels and also have a set percentage
.input-row input {
  float: right;
  width: 65%;
  padding-left: 0;
  margin-bottom: 0;
  border: 0;
}
