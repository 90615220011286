//
// Ratchicons
// --------------------------------------------------

@font-face {
  font-family: Ratchicons;
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/ratchicons.eot');
  src: url('../fonts/ratchicons.eot?#iefix') format('embedded-opentype'),
       url('../fonts/ratchicons.woff') format('woff'),
       url('../fonts/ratchicons.ttf') format('truetype'),
       url('../fonts/ratchicons.svg#svgFontName') format('svg');
}

.icon {
  display: inline-block;
  font-family: Ratchicons;
  font-size: 24px;
  line-height: 1;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.icon-back                  { &:before { content: '\e80a'; } }
.icon-bars                  { &:before { content: '\e80e'; } }
.icon-caret                 { &:before { content: '\e80f'; } }
.icon-check                 { &:before { content: '\e810'; } }
.icon-close                 { &:before { content: '\e811'; } }
.icon-code                  { &:before { content: '\e812'; } }
.icon-compose               { &:before { content: '\e813'; } }
.icon-download              { &:before { content: '\e815'; } }
.icon-edit                  { &:before { content: '\e829'; } }
.icon-forward               { &:before { content: '\e82a'; } }
.icon-gear                  { &:before { content: '\e821'; } }
.icon-home                  { &:before { content: '\e82b'; } }
.icon-info                  { &:before { content: '\e82c'; } }
.icon-list                  { &:before { content: '\e823'; } }
.icon-more-vertical         { &:before { content: '\e82e'; } }
.icon-more                  { &:before { content: '\e82f'; } }
.icon-pages                 { &:before { content: '\e824'; } }
.icon-pause                 { &:before { content: '\e830'; } }
.icon-person                { &:before { content: '\e832'; } }
.icon-play                  { &:before { content: '\e816'; } }
.icon-plus                  { &:before { content: '\e817'; } }
.icon-refresh               { &:before { content: '\e825'; } }
.icon-search                { &:before { content: '\e819'; } }
.icon-share                 { &:before { content: '\e81a'; } }
.icon-sound                 { &:before { content: '\e827'; } }
.icon-sound2                { &:before { content: '\e828'; } }
.icon-sound3                { &:before { content: '\e80b'; } }
.icon-sound4                { &:before { content: '\e80c'; } }
.icon-star-filled           { &:before { content: '\e81b'; } }
.icon-star                  { &:before { content: '\e81c'; } }
.icon-stop                  { &:before { content: '\e81d'; } }
.icon-trash                 { &:before { content: '\e81e'; } }
.icon-up-nav                { &:before { content: '\e81f'; } }
.icon-up                    { &:before { content: '\e80d'; } }
.icon-right-nav             { &:before { content: '\e818'; } }
.icon-right                 { &:before { content: '\e826'; } }
.icon-down-nav              { &:before { content: '\e814'; } }
.icon-down                  { &:before { content: '\e820'; } }
.icon-left-nav              { &:before { content: '\e82d'; } }
.icon-left                  { &:before { content: '\e822'; } }
