//
// Badges
// --------------------------------------------------

.badge {
  display: inline-block;
  padding: 2px 9px 3px;
  font-size: 12px;
  line-height: 1;
  color: #333;
  background-color: rgba(0,0,0,.15);
  border-radius: 100px;

  // Inverted badges have no background.
  &.badge-inverted {
    padding: 0 5px 0 0;
    background-color: transparent;
  }
}


// Badge modifiers
// --------------------------------------------------

// Main badge
.badge-primary {
  color: #fff;
  background-color: $primary-color;

  &.badge-inverted {
    color: $primary-color;
  }
}

// Positive badge
.badge-positive {
  color: #fff;
  background-color: $positive-color;

  &.badge-inverted {
    color: $positive-color;
  }
}

// Negative badge
.badge-negative {
  color: #fff;
  background-color: $negative-color;

  &.badge-inverted {
    color: $negative-color;
  }
}
