//
// Slider styles (to be used with sliders.js)
// --------------------------------------------------

// Width of slider
.slider {
  width: 100%;
}

// Outer wrapper for slider
.slider {
  overflow: hidden;
  background-color: #000;

  // Inner wrapper for slider (width of all slides together)
  .slide-group {
    position: relative;
    font-size: 0; // Remove spaces from inline-block children
    white-space: nowrap;
    @include transition(all 0s linear);

    // Individual slide
    .slide {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: 14px;
      vertical-align: top; // Ensure that li always aligns to top
    }
  }
}
